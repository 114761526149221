window.setupCKEditor = function (id) {
    window.editor = CKEDITOR.replace(id, {
        uiColor: '#EFEFEF',
        height: 200,
        toolbar: [
            {
                name: 'font',
                items: ['Font', 'FontSize']
            }, {
                name: 'basicstyles',
                items: ['Bold', 'Italic', 'Underline', 'RemoveFormat']
            }, {
                name: 'links',
                items: ['Link']
            }, {
                name: 'align',
                items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight']
            }, {
                name: 'clipboard',
                items: ['Undo', 'Redo']
            }, {
                name: 'paragraph',
                items: ['NumberedList', 'BulletedList']
            }, {
                name: 'insert',
                items: ['Smiley']
            }
        ],
        removePlugins: 'elementspath,tabletools,liststyle,scayt,language,menu,menubutton,contextmenu',
        font_names: "Arial/Arial, Helvetica, sans-serif;" + "Courier New/Courier New, Courier, monospace;" + "Georgia/Georgia, serif;" + "Times New Roman/Times New Roman, Times, serif;",
        fontSize_sizes: '12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;'
    });

    CKEDITOR.on('dialogDefinition', function (e) {
        // Take the dialog name and its definition from the event data.
        dialogName = e.data.name;
        dialogDefinition = e.data.definition;

        // Check if the definition is from the dialog we're
        // interested in (the 'link' dialog).
        if (dialogName === 'link') {
            // Remove the 'Target' and 'Advanced' tabs from the 'Link' dialog.
            dialogDefinition.removeContents('target');
            dialogDefinition.removeContents('advanced');

            // Get a reference to the 'Link Info' tab.
            infoTab = dialogDefinition.getContents('info');
            // remove the anchor option
            infoTab.get('linkType').items.splice(1, 1);
        }
    });
};

var coverTitle = $('#cover_title');
var size = coverTitle.length > 0 ? parseInt(coverTitle.css('font-size').replace('px', '')) : 0;

function update() {
    $('.event_title').textfill({
        maxFontPixels: size,
        widthOnly: true
    });
}

(function () {
    $.fn.bmdIframe = function (options) {
        var self = this;
        var settings = $.extend({
            classBtn: '.bmd-modalButton',
            defaultW: '100%',
            defaultH: '100%'
        }, options);

        $(settings.classBtn).on('click', function (e) {
            if (screen.width < 980)
                $('#invitation').hide();
            var allowFullscreen = $(this).attr('data-bmdFullscreen') || false;

            var data = {
                'src': $(this).attr('data-bmdSrc'),
                'height': $(this).attr('data-bmdHeight') || settings.defaultH,
                'width': $(this).attr('data-bmdWidth') || settings.defaultW
            };

            if (allowFullscreen)
                data.allowfullscreen = "";

            $(self).find("iframe").attr(data);
        });

        this.on('hidden.bs.modal', function () {
            var invitation = $('#invitation');
            if (invitation.is(":visible") === false)
                invitation.show();
            $(this).find('iframe').html("").attr("src", "");
        });

        return this;
    };

})();

$(function () {

    var waiver = $('#waiver');
    window.addEventListener('message', function (event) {
        if (waiver.find('iframe')) {
            if (event.data.closeModal)
                waiver.modal('hide');
        }
    });

    waiver.bmdIframe();

    $('#title').keyup(update);
    update();

    var eventImage = $('#event_image');
    var img = eventImage.find('img');
    if (img.length < 1) {
        if (!eventImage.hasClass('cursor-pointer'))
            eventImage.addClass('cursor-pointer');
    }

    eventImage.click(function () {
        var img = eventImage.find('img');
        if (img.length < 1) {
            $('#click-upload').trigger('click');
        }
    });


    var includeMsg = $('input[name="include_waiver_msg"]');
    includeMsg.change(function () {
        if ($(this).prop('checked'))
            includeMsg.data('value', 'YES');
        else
            includeMsg.data('value', 'NO');
    });

    var imageElement = $('#editable-image');
    imageElement.hide();

    var editExistingPhoto = $('.edit-existing-photo');
    var eventEditableImage = $('#event-editable-image');
    if (eventEditableImage.attr('src') !== undefined && eventEditableImage.attr('src').length > 0) {
        editExistingPhoto.show();
    }


    var originalImageSrc; // assigned when image file is dropped
    var currentImage; // assigned when the Edit button is clicked

    var modal = $('#crop-modal');

    // var featherEditor = new Aviary.Feather({
    //     enableCORS: true,
    //     apiKey: window.adobeAPIKey,
    //     authenticationURL: '/get-auth',
    //     noCloseButton: true,
    //     theme: 'light',
    //     tools: ["enhance", "effects", "frames", "orientation", "stickers", "focus", "resize", "warmth", "brightness", "contrast", "saturation", "sharpness", "colorsplash", "draw", "text", "redeye", "whiten", "blemish"],
    //     appendTo: '',
    //     onSave: function (imageID, newURL) {
    //         var img = document.getElementById(imageID);
    //         img.src = newURL;
    //         featherEditor.close();
    //         $('#image').val(newURL);
    //         showImage('event-editable-image', newURL);
    //     },
    //     onError: function (code, msg) {
    //         console.log(code + ' : ' + msg);
    //     }
    // });

    var clickUpload = $('#click-upload');
    clickUpload.on('click', function (e) {
        $('#click-upload').val('');
        if (eventImage.hasClass('cursor-pointer'))
            eventImage.removeClass('cursor-pointer');
    });


    clickUpload.on('change', function (e) {
        var file = e.originalEvent.target.files[0];
        validateFileType(file);
    });

    function showImage(id, url) {
        var img = $('#event_image').find('img');
        if (img.length < 1) {
            createImage(id);
        } else {
            img.attr('id', id);
        }
        var imagePlacement = $('#' + id);
        imagePlacement.attr('src', url);
        imagePlacement.show();
    }

    function createImage(id) {
        var img = $('<img>');
        if (id !== null) {
            img.attr('id', id);
        }
        $('#event_image').prepend(img);
        return img;
    }

    // Checks if the file type is in the array of supported types
    function fileIsSupported(file) {
        var supportedFileTypes = ['image/jpeg', 'image/png'];
        return supportedFileTypes.indexOf(file.type) >= 0;
    }

    function validateFileType(file) {
        if (fileIsSupported(file)) {
            editExistingPhoto.show();
            setImage(file);
            launchImageEditor();
            return true;
        } else {
            alert('Try a JPEG or PNG image');
            return false;
        }
    }

    var image = $('#crop-modal #cropper-image');

    function setImage(file) {
        image.attr('src', window.URL.createObjectURL(file));

        // imageElement.attr('src', window.URL.createObjectURL(file));
        // originalImageSrc = imageElement.attr('src');
    }

    function launchImageEditor() {
        // Get the image to be edited
        // `[0]` gets the image itself, not the jQuery object
        currentImage = $('#editable-image')[0];

        var imageMask = $("div.main_image_mask");

        modal.modal('show');

        // currentImage.cropper({
        //     initialAspectRatio: 4 / 3,
        //     aspectRatio: 4 / 3,
        //     viewMode: 0,
        //     guides: false,
        //     center: false,
        //     background: true,
        //     highlight: false,
        //     cropBoxMovable: false,
        //     cropBoxResizable: false,
        //     toggleDragModeOnDblclick: false,
        //     minContainerWidth: 180,
        //     minContainerHeight: 150,
        //     dragMode: 'move',
        //     autoCropArea: 0.8,
        //     autoCrop: true,
        //     modal: false
        // });

        // featherEditor.launch({
        //     image: currentImage.id,
        //     url: currentImage.src,
        //     hiresUrl: currentImage.src,
        //     forceCropPreset: ["Crop your photo", imageMask.width() + ":" + imageMask.height()],
        //     forceCropMessage: "Drag the corners of the box to crop this photo"
        // });
    }

    var cropper;
    modal.on('shown.bs.modal', function () {
        var imageMask = $('div.main_image_mask');
        cropper = new Cropper(image[0], {
            initialAspectRatio: 4 / 3,
            aspectRatio: imageMask ? imageMask.width() / imageMask.height() : 4 / 3,
            viewMode: 0,
            guides: false,
            center: false,
            background: true,
            highlight: false,
            cropBoxMovable: false,
            cropBoxResizable: false,
            toggleDragModeOnDblclick: false,
            minContainerWidth: 180,
            minContainerHeight: 150,
            dragMode: 'move',
            autoCropArea: 1,
            autoCrop: true,
            modal: false,
            checkCrossOrigin: false
        });
    }).on('hidden.bs.modal', function () {
        cropper.destroy();
        cropper = null;
    });

    editExistingPhoto.click(function () {
        // Get the image to be edited
        // `[0]` gets the image itself, not the jQuery object
        currentImage = $('#event-editable-image');

        var imageMask = $("div.main_image_mask");

        // featherEditor.launch({
        //     tools: ["enhance", "effects", "frames", "orientation", "stickers", "focus", "resize", "warmth", "brightness", "contrast", "saturation", "sharpness", "colorsplash", "draw", "text", "redeye", "whiten", "blemish"],
        //     image: currentImage.id,
        //     url: currentImage.src,
        //     hiresUrl: currentImage.src
        // });

        modal.modal('show');

        image.attr('src', currentImage.attr('src'));
    });


    $('#crop-modal #crop').on('click', function () {
        modal.modal('hide');
        var imageMask = $("div.main_image_mask");

        if (cropper) {
            canvas = cropper.getCroppedCanvas({
                width: imageMask.width(),
                height: imageMask.height()
            });
            $('#image').val(canvas.toDataURL());
            showImage('event-editable-image', canvas.toDataURL());
        }

    });

    var evtDateTime = $('#event-date-time');
    evtDateTime.find('.time').timepicker({
        'timeFormat': 'g:i A',
        'minTime': '6:00 AM',
        'maxTime': '11:00 PM',
        'showDuration': false
    }).change(changeTime).on('changeTime', changeTime);

    evtDateTime.find('.date').datepicker({
        'format': 'mm/dd/yyyy',
        'autoclose': true,
        'startDate': 'today'
    }).change(changeDate).on('changeDate', changeDate);

    function changeDate(ev) {
        angular.element($('#start_date')).triggerHandler('input');
        angular.element($('#rsvp_by')).triggerHandler('input');
    }

    function changeTime(ev) {
        angular.element($('#start_time')).triggerHandler('input');
        angular.element($('#end_time')).triggerHandler('input');
    }


    $('#rsvp_by').datepicker({
        'format': 'mm/dd/yyyy',
        'autoclose': true,
        'startDate': 'today'
    }).change(changeDate).on('changeDate', changeDate);

    var element = document.getElementById('event-date-time');
    var datapair = null;
    if (element !== undefined && element !== null)
        datapair = new Datepair(element);

    // if ($('#message').length > 0)
    //     window.setupCKEditor('message');


    $('#change-design').click(function () {
        var changeDesign = $('#change-design');

        if (changeDesign.find('i').hasClass('fa-arrow-down')) {
            changeDesign.find('i').removeClass('fa-arrow-down');
            changeDesign.find('i').addClass('fa fa-arrow-up');
        } else {
            changeDesign.find('i').removeClass('fa-arrow-up');
            changeDesign.find('i').addClass('fa fa-arrow-down');
        }
    });

    var swiper = new Swiper('.swiper-container', {
        pagination: '.swiper-pagination',
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        slidesPerView: 3,
        // centeredSlides: true,
        paginationClickable: true,
        // spaceBetween: 30,
        observer: true,
        observeParents: true
    });

    var rsvp = $('#rsvp');
    if (rsvp.length > 0 && rsvp.val() !== 'no')
        $('.adults-kids-cnt').show();


    $('.container').on('click', '.rsvp a', function () {
        var selected = $(this).data('value');
        var toggle = $(this).data('toggle');
        $(this).parent().next('.' + toggle).prop('value', selected);
        $(this).parent().find('a[data-toggle="' + toggle + '"]').not('[data-value="' + selected + '"]').removeClass('active').addClass('not-active');
        $(this).parent().find('a[data-toggle="' + toggle + '"][data-value="' + selected + '"]').removeClass('not-active').addClass('active');

        $('#rsvp').val($(this).data('value'));

        if (selected === 'no')
            $('.adults-kids-cnt').hide();
        else
            $('.adults-kids-cnt').show();
    });


    $('.btn-default.cancel-event').on('click', function () {
        swal({
            title: 'Are you sure you want to delete your invitation?',
            text: "Warning: this will delete your invitation but will NOT cancel your event. If you would like to cancel your party, please contact the location where you are hosting directly.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete invitation',
            cancelButtonText: 'Close'
        }).then(function () {
            $.ajax({
                url: '/event/cancel-event',
                type: 'POST',
                data: {
                    eventId: $('.btn-default.cancel-event').data('event-id')
                },
                success: function () {

                    $('#send-message-modal').modal('show');

                    $('.send-cancellation').click(function () {
                        var modal = $('#send-message-modal');
                        var guestIds = [];

                        modal.find('input[name="guestIds"]:checked').each(function () {
                            guestIds.push($(this).val());
                        });
                        var message = modal.find('textarea[name="message"]').val();

                        $.ajax({
                            url: '/event/send-cancellation-notification',
                            type: 'POST',
                            data: {
                                guestIds: guestIds,
                                message: message
                            },
                            success: function () {
                                modal.modal('hide');
                                swal({
                                    title: 'Deleted!',
                                    text: 'Please contact the store directly to cancel your event.',
                                    type: 'success'
                                }).then(function () {
                                    window.location.reload();
                                });
                            }
                        });
                    });


                    $('.skip-cancellation').click(function () {
                        swal({
                            title: 'Deleted!',
                            text: 'Your invitation has been deleted.',
                            type: 'success'
                        }).then(function () {
                            window.location.reload();
                        });
                    });

                }
            });
        }).catch(swal.noop);
    });


    $('.btn-default.message-event-guest').on('click', function () {

        $('#send-guest-message-modal').modal('show');

        var sendCancellationElem = $('.send-cancellation');
        sendCancellationElem.unbind();
        sendCancellationElem.click(function () {
            var modal = $('#send-guest-message-modal');
            var guestIds = [];

            modal.find('input[name="guestIds"]:checked').each(function () {
                guestIds.push($(this).val());
            });
            var message = modal.find('textarea[name="message"]').val();
            modal.modal('hide');

            $.ajax({
                url: '/event/send-guest-message',
                type: 'POST',
                data: {
                    guestIds: guestIds,
                    message: message
                },
                success: function () {
                    swal({
                        title: 'Success!',
                        text: 'Your message has been sent.',
                        type: 'success'
                    }).then(function () {
                        window.location.reload();
                    });
                },
                error: function () {
                }
            });
        });
    });

    $('.btn-default.resend-invitation').on('click', function () {
        var _this = $(this);
        swal({
            title: 'Would you like to resend this invitation?',
            text: "After clicking send, a new invitation will appear shortly in your guests inbox.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Resend Invitation',
            cancelButtonText: 'Close'

        }).then(function () {
            $.ajax({
                url: '/event/resend-invitation',
                type: 'POST',
                data: {
                    guestId: _this.data('guest-id')
                },
                success: function () {
                    swal({
                        title: 'Success!',
                        text: 'Your Invitation has been resent.',
                        type: 'success'
                    }).then(function () {
                        window.location.reload();
                    });
                },
                error: function () {
                }
            });
        }).catch(swal.noop);
    });

    $(".filter-button").click(function () {
        $(".filter-button").removeClass('active');
        $(this).addClass('active');
        var value = $(this).attr('data-filter');
        var filter = $('.filter');
        if (value === 'everyone') {
            filter.show();
        } else {
            filter.not('.' + value).hide();
            filter.filter('.' + value).show();
        }
    });


    $('a.delete-draft, a.delete-canceled-event').on('click', function () {
        swal({
            title: 'Remove Event',
            text: "Are you sure you want to remove this invitation from your Events list? You'll no longer have access to it once it's removed.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Remove'
        }).then(function () {
            $.ajax({
                url: '/event/delete-event?eventId=' + $('a.delete-draft, a.delete-canceled-event').data('event-id'),
                type: 'DELETE',
                success: function () {
                    swal({
                        title: 'Deleted!',
                        text: 'Your event has been deleted.',
                        type: 'success'
                    }).then(function () {
                        window.location.reload();
                    });
                }
            });

        }).catch(swal.noop);
    });

    $('a.delete-faq').on('click', function (e) {
        $this = $(this);
        e.preventDefault();
        swal({
            title: 'Delete Question?',
            text: "Are you sure you want to delete this faq",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Remove'
        }).then(function () {
            $.ajax({
                url: '/admin/manage-faqs/delete?Id=' + $this.data('id'),
                type: 'DELETE',
                success: function () {
                    swal({
                        title: 'Deleted!',
                        text: '',
                        type: 'success'
                    }).then(function () {
                        window.location.reload();
                    });
                }
            });
        }).catch(swal.noop);
    });

    $('a.create-faq').on('click', function (e) {
        $this = $(this);
        e.preventDefault();
        $.ajax({
            url: '/admin/manage-faqs/new',
            type: 'POST',
            data: {
                question: $this.parents('#' + $this.data('id')).find('.question-text').val(),
                answer: $this.parents('#' + $this.data('id')).find('.answer-text').val(),
                display_order: $this.parents('#' + $this.data('id')).find('.displayorder-text').val()
            },
            success: function () {
                swal({
                    title: 'Saved!',
                    text: 'New FAQ saved !',
                    type: 'success'
                }).then(function () {
                    window.location.reload();
                });
            },
            error: function (response) {
                var errors = response.responseJSON;
                var msg = '';
                angular.forEach(errors, function (index, value) {
                    msg += '<li class="alert-msg">' + index[0] + '</li>';
                });
                $('.flash-message-new').html('<div class="alert alert-danger"><strong>' + msg + '</strong></div>');
            }
        });
    });

    $('a.edit-faq').on('click', function (e) {
        $this = $(this);
        e.preventDefault();
        $.ajax({
            url: '/admin/manage-faqs/edit?Id=' + $this.data('id'),
            type: 'POST',
            data: {
                question: $this.parents('#' + $this.data('id')).find('.question-text').val(),
                answer: $this.parents('#' + $this.data('id')).find('.answer-text').val(),
                display_order: $this.parents('#' + $this.data('id')).find('.displayorder-text').val()
            },
            success: function () {
                swal({
                    title: 'Saved!',
                    text: 'Question/Answer is updated',
                    type: 'success'
                }).then(function () {
                    window.location.reload();
                });
            },
            error: function (response) {
                var errors = response.responseJSON;
                var msg = '';
                angular.forEach(errors, function (index, value) {
                    msg += '<li class="alert-msg">' + index[0] + '</li>';
                });
                $this.parents('#' + $this.data('id')).find('.flash-message-edit').html('<div class="alert alert-danger"><strong>' + msg + '</strong></div>');
            }
        });
    });
});