var app = angular.module("inviteApp", ['ngCookies', 'ngSanitize'], function ($interpolateProvider) {
    $interpolateProvider.startSymbol('<%');
    $interpolateProvider.endSymbol('%>');
});

app.controller("inviteCtrl", function ($scope, $http, $cookies, $window) {

    $scope.formSubmit = function (action) {
        $('#' + action).val(action);

        var backgroundImage = $('.full #invitation .bg_mid');
        var url = backgroundImage.css('background-image').replace('url(', '').replace(')', '').replace(/\"/gi, "");
        url = getLocalURL(url);
        backgroundImage.attr('style', 'background-image: url(' + url + ') !important');

        var maskImage = $('.main_image_mask');
        url = maskImage.css('background-image').replace('url(', '').replace(')', '').replace(/\"/gi, "");
        url = getLocalURL(url);
        if (url !== 'none')
            maskImage.attr('style', 'background-image: url(' + url + ') !important');

        html2canvas($("#design-template"), {
            useCORS: true,
            onrendered: function (canvas) {
                var data = canvas.toDataURL('image/png');
                var encodedPng = data.substring(data.indexOf(',') + 1, data.length);
                $('#thumbnail').val(encodedPng);
                var eventCreate = $('#event-create');
                if (eventCreate.length > 0)
                    eventCreate.submit();
                else
                    $('#event-update').submit();
            }
        });
    }


    function getLocalURL(url) {
        if (url.indexOf('bounceu') > -1) {
            return url.replace('https://s3-us-west-2.amazonaws.com/static.bounceu.com/invites/background-images/', '/images/bounceu/');
        } else {
            return url.replace('https://s3-us-west-2.amazonaws.com/static.pumpitupparty.com/invites/background-images/', '/images/pumpitup/');
        }
    }

    function initializeEvent() {
        var event = {};
        var includedWaiverMsg = $('input[name="include_waiver_msg"]').data('value');

        event.template_id = $scope.template_id;
        event.title = $scope.title;
        event.host = $scope.host;
        event.phone = $scope.phone;
        event.rsvp_by = $scope.rsvp_by;
        event.start_date = $scope.start_date;
        event.start_time = $scope.start_time;
        event.end_time = $scope.end_time;
        event.location_name = $scope.location_name;
        event.street_address = $scope.street_address;
        event.city = $scope.city;
        event.state = $scope.state;
        event.zip_code = $scope.zip_code;
        event.message = $scope.message === undefined ? '' : $scope.message;
        event.waiver_url = includedWaiverMsg === 'YES' ? $scope.waiver_url : '';
        event.image = $('#image').val();

        return event;
    }

    $scope.show = function (action) {

        var event = initializeEvent();
        var win = $window;
        $.ajax({
            url: '/event/validate-preview',
            async: false,
            type: 'POST',
            data: {
                template_id: event.template_id,
                name: event.title,
                host: event.host,
                phone: event.phone,
                rsvp_by: event.rsvp_by,
                start_date: event.start_date,
                start_time: event.start_time,
                end_time: event.end_time,
                location_name: event.location_name,
                street_address: event.street_address,
                city: event.city,
                state: event.state,
                zip_code: event.zip_code,
                message: event.message,
                waiver_url: event.waiver_url,
                image: event.image
            },
            success: function () {
                $('.flash-message').html('');
                if (action === 'preview') {
                    $cookies.put('currentEvent', JSON.stringify(event));
                    win.open('/event/preview', '_blank');
                } else {
                    win.print();
                }
            },
            error: function (response) {
                var errors = response.responseJSON;
                var msg = '';
                angular.forEach(errors, function (index, value) {
                    msg += '<li class="alert-msg">' + index[0] + '</li>';
                });
                $('.flash-message').html('<div class="alert alert-danger"><strong>' + msg + '</strong></div>');
            }
        });

    }


    $scope.download = function (action) {
        var event = initializeEvent();
        var win = $window;
        var data = {
            template_id: event.template_id,
            name: event.title,
            host: event.host,
            phone: event.phone,
            rsvp_by: event.rsvp_by,
            start_date: event.start_date,
            start_time: event.start_time,
            end_time: event.end_time,
            location_name: event.location_name,
            street_address: event.street_address,
            city: event.city,
            state: event.state,
            zip_code: event.zip_code,
            message: event.message,
            waiver_url: event.waiver_url,
            image: event.image
        };
        $.ajax({
            url: '/event/download',
            type: 'POST',
            data: data,
            xhrFields: {
                responseType: 'blob'
            },
            success: function (blob) {
                $('.flash-message').html('');
                // var link = document.createElement('a');
                // document.body.appendChild(link);
                // link.href = window.URL.createObjectURL(blob);
                // link.download = "invitation.pdf";
                // link.click();
                saveAs(blob, "invitation.jpg");
            },
            error: function (response) {
                $.ajax({
                    url: '/event/validate',
                    type: 'POST',
                    data: data,
                    error: function (response) {
                        var errors = response.responseJSON;
                        var msg = '';
                        angular.forEach(errors, function (index, value) {
                            msg += '<li class="alert-msg">' + index[0] + '</li>';
                        });
                        $('.flash-message').html('<div class="alert alert-danger"><strong>' + msg + '</strong></div>');
                    }
                });
            }
        });

    }
}).directive('ckEditor', function () {
    return {
        require: '?ngModel',
        link: function ($scope, elm, attr, ngModel) {

            var ck = CKEDITOR.replace(elm[0], {
                uiColor: '#EFEFEF',
                height: 200,
                toolbar: [
                    {
                        name: 'font',
                        items: ['Font', 'FontSize']
                    }, {
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Underline', 'RemoveFormat']
                    }, {
                        name: 'links',
                        items: ['Link']
                    }, {
                        name: 'align',
                        items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight']
                    }, {
                        name: 'clipboard',
                        items: ['Undo', 'Redo']
                    }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList']
                    }, {
                        name: 'insert',
                        items: ['Smiley']
                    }
                ],
                removePlugins: 'elementspath,tabletools,liststyle,scayt,language,menu,menubutton,contextmenu',
                font_names: "Arial/Arial, Helvetica, sans-serif;" + "Courier New/Courier New, Courier, monospace;" + "Georgia/Georgia, serif;" + "Times New Roman/Times New Roman, Times, serif;",
                fontSize_sizes: '12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;'
            });

            CKEDITOR.on('dialogDefinition', function (e) {
                // Take the dialog name and its definition from the event data.
                dialogName = e.data.name;
                dialogDefinition = e.data.definition;

                // Check if the definition is from the dialog we're
                // interested in (the 'link' dialog).
                if (dialogName === 'link') {
                    // Remove the 'Target' and 'Advanced' tabs from the 'Link' dialog.
                    dialogDefinition.removeContents('target');
                    dialogDefinition.removeContents('advanced');

                    // Get a reference to the 'Link Info' tab.
                    infoTab = dialogDefinition.getContents('info');
                    // remove the anchor option
                    infoTab.get('linkType').items.splice(1, 1);
                }
            });


            ck.on('instanceReady', function () {
                ck.setData(ngModel.$viewValue);
            });

            ck.on('pasteState', function () {
                $scope.$apply(function () {
                    ngModel.$setViewValue(ck.getData());
                });
            });

            ngModel.$render = function (value) {
                ck.setData(ngModel.$modelValue);
            };
        }
    };
}).directive('date', function (dateFilter) {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {

            var dateFormat = attrs['date'] || 'MM/dd/yyyy';

            ctrl.$formatters.unshift(function (modelValue) {
                return dateFilter(modelValue, dateFormat);
            });
        }
    };
}).filter('titlecase', function () {
    return function (input) {
        var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;

        input = input.toLowerCase();
        return input.replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, function (match, index, title) {
            if (index > 0 && index + match.length !== title.length &&
                match.search(smallWords) > -1 && title.charAt(index - 2) !== ":" &&
                (title.charAt(index + match.length) !== '-' || title.charAt(index - 1) === '-') &&
                title.charAt(index - 1).search(/[^\s-]/) < 0) {
                return match.toLowerCase();
            }

            if (match.substr(1).search(/[A-Z]|\../) > -1) {
                return match;
            }

            return match.charAt(0).toUpperCase() + match.substr(1);
        });
    }
});


app.controller("previewCtrl", function ($scope, $cookies) {

    if ($cookies.get('currentEvent') !== undefined)
        $scope.currentEvent = JSON.parse($cookies.get('currentEvent'));
});


function getUrlVars() {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

app.controller("guestsCtrl", function ($scope, $http, $cookies, $location, inlineEdit) {
    var guests = this;
    $scope.editor = new inlineEdit(guests.record);
    $scope.collection = [];

    $scope.emailRegExp = /(?=^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)(?=^[a-zA-Z0-9.!#$%&'*+/=?^_'{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$)/;
    // ^[a-zA-Z0-9.!#$%&'*+/=?^_'{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$
    $http({
        method: 'GET',
        url: '/guest/get-event-guests?eventId=' + getUrlVars()['EventId'],
        contentType: 'application/json'
    }).then(function success(response) {
        $scope.collection = response.data;
    }, function error(response) {
        console.log(response.statusText);
    });
})
    .factory("inlineEdit", function ($http) {
        return function (recordCache, Resource) {
            this.recordCache = recordCache;
            this.resource = Resource;
            var previous;
            this.edit = function (collection, record) {
                previous = {
                    id: record.id,
                    name: record.name,
                    email: record.email
                };
                angular.forEach(collection, function (item) {
                    delete item.editing;
                });
                angular.extend(this.recordCache = record, {editing: true});
            };
            this.cancel = function (collection) {
                angular.forEach(collection, function (item) {
                    if (item.id === previous.id) {
                        item.name = previous.name;
                        item.email = previous.email;
                    }

                });
                delete this.recordCache.editing;
                this.recordCache = {};
            };
            this.save = function () {
                $http({
                    method: 'POST',
                    url: '/guest/edit-guest',
                    contentType: 'application/json',
                    data: {
                        id: this.recordCache.id,
                        name: this.recordCache.name,
                        email: this.recordCache.email
                    }
                }).then(function success(response) {

                }, function error(response) {
                    console.log(response.statusText);
                });

                delete this.recordCache.editing;
                this.recordCache = {};
            };
            this.add = function (collection, records) {
                var entries = records.replace(/(?:\r\n|\r|\n)/g, ',').replace(/\s/g, '').split(',');

                $('#guests').val('');

                var emails = [];
                var names = [];

                var regex = /(?=^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)(?=^[a-zA-Z0-9.!#$%&'*+/=?^_'{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$)/;
                // ^[a-zA-Z0-9.!#$%&'*+/=?^_'{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$

                for (var i = 0; i < entries.length; i++) {
                    var entry = entries[i];
                    if (entry.indexOf('<') > 0) {
                        var parts = entry.split('<');

                        if (!(parts[1].substr(0, parts[1].indexOf('>') === "" || !regex.test(parts[1].substr(0, parts[1].indexOf('>')))))) {
                            names.push(parts[0].trim());
                            emails.push(parts[1].substr(0, parts[1].indexOf('>')));
                        }
                    }
                }

                var addedGuests = [];
                angular.forEach(emails, function (value, index) {
                    var hasMatch = false;

                    angular.forEach(collection, function (person) {
                        if (person.email === value) {
                            hasMatch = true;
                        }
                    });

                    if (!hasMatch) {
                        addedGuests.push({name: names[index], email: value});
                    }
                });

                if (emails.length === 0) {
                    var validEmails = [];

                    for (var i = 0; i < entries.length; i++) {
                        if (!(entries[i] === "" || !regex.test(entries[i]))) {
                            validEmails.push(entries[i]);
                        }
                    }

                    if (validEmails.length > 0) {
                        angular.forEach(validEmails, function (value) {
                            var hasMatch = false;

                            angular.forEach(collection, function (person) {
                                if (person.email === value) {
                                    hasMatch = true;
                                }
                            });

                            if (!hasMatch) {
                                addedGuests.push({name: value.split('@')[0], email: value});
                            }
                        });
                    }
                }

                $http({
                    method: 'POST',
                    url: '/guest/add-event-guests',
                    contentType: 'application/json',
                    data: {
                        eventId: getUrlVars()['EventId'],
                        guests: addedGuests
                    }
                }).then(function success(response) {
                    var index = 0;
                    angular.forEach(response.data, function (item) {
                        collection[index] = item;
                        index++;
                    });
                }, function error(response) {
                    console.log(response.statusText);
                });

                var btnAddGuest = $("#btnAddGuest"),
                    btnComplete = $("#btnComplete");
                btnComplete.prop("disabled", false);
                btnComplete.addClass('btn-success');// NOT a toggle
            };
            this.delete = function (collection, record) {
                $http({
                    method: 'DELETE',
                    url: '/guest/delete-guest?id=' + record.id
                }).then(function success(response) {

                }, function error(response) {
                    console.log(response.statusText);
                });

                var index = collection.indexOf(record);
                collection.splice(index, 1);
            };
        }
    });


app.filter('customDateSuffix', function ($filter) {
    return function (myDate) {

        if (typeof myDate === 'undefined') {
            return myDate;
        }
        var dateTokens = myDate.split("-");
        var dayM = parseInt(dateTokens[2]);

        return dayM + (dayM % 10 === 1 && dayM !== 11 ? 'st' : (dayM % 10 === 2 && dayM !== 12 ? 'nd' : (dayM % 10 === 3 && dayM !== 13 ? 'rd' : 'th')));
    };
});